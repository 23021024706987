export const success = {
  ADMIN_CREATED: 'Admin Created Successfully.',
  ROLE_CREATED: 'Role Created Successfully.',
  DISEASE_CREATED: 'Disease Created Successfully.',
  SPECIALITY_CREATED: 'Doctor Speciality Created Successfully.',
  MEDICINE_CREATED: 'Medicine Approved Successfully.',
  STATE_COUNCIL_CREATED: 'State Council Created Successfully',
  SYMPTOM_CREATED: 'Symptom Created Successfully.',
  ADD_ON_CREATED: 'Add ON Service Created Successfully.',
  PLAN_CREATED: 'Plan Created Successfully.',
  PLAN_FREQUENCY_CREATED: 'Plan Frequency Created Successfully.',
  SERVICE_CREATED: 'Service Created Successfully.',
  SERVICE_TO_PLAN_CREATED: 'Services to Plan Added Successfully.',
  POLICY_CREATED: 'Policy Created Successfully.',
  APPROVAL_STEPS_CREATED: 'Approval/Business Steps Created Successfully.',
  HOLIDAY_CREATED: 'Holiday Created Successfully.',
  EMAIL_TEMPLATE_CREATED: 'E-Mail Template Created Successfully.',
  SMS_TEMPLATE_CREATED: 'SMS Template Created Successfully.',
  PUSH_TEMPLATE_CREATED: 'Push Notification Template Created Successfully.',
  MODULE_CREATED: 'Module Created Successfully.',
  RX_TEMPLATE_CREATED: 'RX Template Created Successfully.',
  RX_CATEGORY_CREATED: 'RX Category Created Successfully.',
  RX_SUBCATEGORY_CREATED: 'RX Sub-Category Created Successfully.',
  RX_TEMPLATE_COMPONENT: 'RX Template Component Created Successfully.',
  TEST_REQUESTED_CREATED: 'Test Requested Created Successfully.',
  SUPPORT_CREATED: 'Support Created Successfully.',
  SETTLEMENT_DONE: 'Settlement Done Successfully.',

  HOLIDAY_FETCHED: 'Holidays Fetched Successfully.',
  CLINIC_APPROVED: 'Clinic Approved Successfully.',
  MAIL_SEND: 'E-Mail Sent Successfully.',
  DOCTOR_CERTIFIED: 'Doctor Certified Successfully.',
  ADMIN_UPDATED: 'Admin Updated Successfully.',
  SYSTEM_ROLE_UPDATED: 'Role Updated Successfully.',
  PERMISSION_UPDATED: 'Permission Updated Successfully.',
  PASSWORD_UPDATED: 'Password Updated Successfully.',
  CLINIC_UPDATED: 'Clinic Updated Successfully.',
  CLINIC_FEES_UPDATED: 'Business Fees Updated Successfully.',
  DISEASE_UPDATED: 'Disease Updated Successfully.',
  SPECIALITY_UPDATED: 'Doctor Speciality Updated Successfully.',
  MEDICINE_UPDATED: 'Medicine Updated Successfully.',
  STATE_COUNCIL_UPDATED: 'State Council Updated Successfully',
  SYMPTOM_UPDATED: 'Symptom Updated Successfully.',
  ADD_ON_UPDATED: 'Add ON Service Updated Successfully.',
  PLAN_UPDATED: 'Plan Updated Successfully.',
  PLAN_FREQUENCY_UPDATED: 'Plan Frequency Updated Successfully.',
  SERVIVE_UPDATED: 'Service Updated Successfully.',
  SERVICE_TO_PLAN_UPDATED: 'Services to Plan Updated Successfully.',
  POLICY_UPDATED: 'Policy Updated Successfully.',
  APPROVAL_STEPS_UPDATED: 'Approval/Business Steps Updated Successfully.',
  SETTINGS_UPDATED: 'Settings Updated Successfully.',
  HOLIDAY_UPDATED: 'Holiday Updated Successfully.',
  EMAIL_TEMPLATE_UPDATED: 'E-Mail Template Updated Successfully.',
  SMS_TEMPLATE_UPDATED: 'SMS Template Updated Successfully.',
  PUSH_TEMPLATE_UPDATED: 'Push Notification Template Updated Successfully.',
  MODULE_UPDATED: ' Module Updated Successfully.',
  RX_TEMPLATE_UPDATED: 'RX Template Updated Successfully.',
  RX_CATEGORY_UPDATED: 'RX Category Updated Successfully.',
  RX_SUBCATEGORY_UPDATED: 'RX Sub-Category Updated Successfully.',
  TEST_REQUESTED_UPDATED: 'Test Requested Updated Successfully.',
  FEES_UPDATED: 'Fees Updated Successfully.',

  DOCUMENT_UPLOADED: 'Document Uploaded Successfully.',

  RX_CATEGORY_DELETED: 'RX Category Deleted Successfully.',
  RX_SUBCATEGORY_DELETED: 'RX Sub-Category Deleted Successfully.',
  RX_TEMPLATE_COMPONENT_DELETED: 'RX Template Component Deleted Successfully.',
  DOCUMENT_DELETED: 'Document Deleted Successfully.',
}
