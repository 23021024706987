/* eslint-disable import/no-anonymous-default-export */
// reducer is function who has access for state& action
// eslint-disable-next-line no-unused-vars
import {createReducer, createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useQuery, gql} from '@apollo/client'
import moment from 'moment'
import {subDays} from 'date-fns'
import {updateDateFormat} from '../../data/config'
const initialState = {
  auth: null,
  startDate: updateDateFormat(moment(new Date()).utc().subtract(1, 'month')),
  // moment(new Date()).utc().subtract(1, 'month'),
  endDate: updateDateFormat(new Date()),
  //  moment(new Date()).utc(),
  uuid: null,
}

const userReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateuuid(state, action) {
      state.uuid = action.payload
    },

    updateStartDate(state, action) {
      state.startDate = action.payload
    },

    updateEndDate(state, action) {
      state.endDate = action.payload
    },

    updateState(state, action) {
      state.auth = action.payload
    },

    logout: (state) => {
      state.auth = null
      state.startDate = updateDateFormat(moment().subtract(1, 'month'))
      // moment(new Date()).utc().subtract(1, 'month')
      state.endDate = updateDateFormat(new Date())
      // state.uuid = null
    },
  },
  // extraReducers: {
  //   [fetchUserName.fulfilled]: (state, action) => {
  //     state.userId = action.payload
  //   },
  //   [fetchUserName.pending]: (state, action) => {
  //     state.userId = 'Loading!!!'
  //   },
  //   [fetchUserName.rejected]: (state, action) => {
  //     state.userId = 'Try Again!!!'
  //   },
  // },
})
export const actionsUsers = userReducer.actions
export default userReducer.reducer
