import {useEffect, useState} from 'react'
import '../../../App.css'

import {toAbsoluteUrl} from '../../../_ePulse/helpers'
import {useQuery} from '@apollo/client'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import {CLININC_APPROVAL_REQUEST} from '../../GraphQLQueries/Clinic'
import {checkPermission} from '../../App'
import {useSelector} from 'react-redux'

import {ToastMessageContainer} from '../../../_ePulse/layout/components/ToastMessageContainer'
import useApi from '../../../hooks/useQuery'
export const ApprovalRequest = () => {
  const navigate = useNavigate()
  const reduxData: any = useSelector((state) => {
    return state
  })

  const {
    data: resData,
    loading: loading1,
    error: error1,
    refetch: refetch1,
  } = useApi(false, CLININC_APPROVAL_REQUEST, {
    endDate: moment(reduxData.endDate).endOf('day'),
    startDate: moment(reduxData.startDate).startOf('day'),
  })

  useEffect(() => {
    if (resData) {
      setApprovalList(resData.getApprovalPendingBusinessList.data)
    }
  }, [resData])

  // const {loading, error, refetch} = useQuery(CLININC_APPROVAL_REQUEST, {
  //   variables: {
  //     endDate: moment(reduxData.endDate).endOf('day'),
  //     startDate: moment(reduxData.startDate).startOf('day'),
  //     // endDate: moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
  //     // startDate: moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
  //   },
  //   onError: (error: any) => {
  //     // if (error?.networkError?.statusCode === 440) {
  //     //   toast.error(`${error?.networkError?.result?.errors[0]?.message}`)
  //     // }
  //   },
  //   onCompleted: (data) => {
  //     setApprovalList(data.getApprovalPendingBusinessList.data)
  //   },
  // })

  const [approvalList, setApprovalList] = useState([])

  return (
    <div className='dashboard-block dashboard-block-requests'>
      {/* <ToastMessageContainer /> */}
      <div className='dashboard-block-header'>
        <img
          src={toAbsoluteUrl('/media/ePulse/icons/dashboard/requests.svg')}
          className='h-32px'
          alt=''
        />
        <span className='block-title'>Approval Request</span>
      </div>
      <div className='dashboard-block-body'>
        <div style={{height: '353px', overflow: 'hidden auto'}} className='d-flex flex-column'>
          {!approvalList.length ? (
            <div className='d-flex flex-row align-items-center justify-content-center h-100'>
              No Approval Request
            </div>
          ) : (
            approvalList.map((activity: any, index: number) => {
              const clinic: any = activity
              return (
                <div
                  key={index}
                  className='dashboard-info-row request-row'
                  onClick={() => {
                    checkPermission('getBusinessDetails') &&
                      navigate('/business/view', {state: {clinicId: clinic.businessId}})
                  }}
                >
                  <div>
                    <div className=''>
                      {activity?.firstName} {activity?.lastName} want to join as{' '}
                      {activity?.businessType}{' '}
                      <span className='info-title'>
                        {activity?.businessName}
                        <span className='info-date'>
                          {' '}
                          ({moment(activity?.createdDate).format('YYYY-MM-DD')})
                        </span>
                      </span>
                    </div>

                    {/* <button
                      className='rounded ms-2 ePulse-appColor-primary text-white border-1 border-light'
                      onClick={() =>
                        navigate('/business/view', {state: {clinicId: clinic.businessId}})
                      }
                    >
                      View
                    </button> */}
                    {/* <img
                      src={toAbsoluteUrl('/media/ePulse/view_eye.svg')}
                      onClick={() =>
                        navigate('/business/view', {state: {clinicId: clinic.businessId}})
                      }
                      className='view-icon'
                      alt=''
                    /> */}
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}
