/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {useSelector, useDispatch} from 'react-redux'
import {actionsUsers} from '../../../../setup/redux/rootReducer'
import {gql, useMutation} from '@apollo/client'
import {v4 as uuid} from 'uuid'
import {LOGIN_MUTATION, OTPVERIFY_MUTATION} from '../../../GraphQLQueries/Auth'
import {Modal} from 'react-bootstrap'
import useMutationApi from '../../../../hooks/useMutation'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [ip, setIP] = useState('')
  const [otp, setOtp] = useState('')
  const [modalState, setModalState] = useState(false)
  const [browserDetails, setBrowserDetails] = useState<any>(null)
  const [unique_id, setuuid] = useState(uuid())

  const getData = async () => {
    const response = await fetch('https://api.ipify.org/?format=json')
    const data = await response.json()
    setIP(data.ip)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const browser = {
      name: getBrowserName(),
      userAgent: navigator.userAgent,
      // version: getBrowserVersion(),
      // platform: getBrowserPlatform(),
    }

    setBrowserDetails(browser)
  }, [])

  const getBrowserName = () => {
    // const userAgent = navigator.userAgent
    // const browsers = ['Chrome', 'Firefox', 'Safari', 'Opera', 'Edge', 'IE']

    // for (let i = 0; i < browsers.length; i++) {
    //   if (userAgent.indexOf(browsers[i]) > -1) {
    //     return browsers[i]
    //   }
    // }

    var sBrowser,
      sUsrAg = navigator.userAgent

    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = 'Mozilla Firefox'
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
      sBrowser = 'Samsung Internet'
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
      sBrowser = 'Opera'
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf('Trident') > -1) {
      sBrowser = 'Microsoft Internet Explorer'
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf('Edge') > -1 || sUsrAg.indexOf('Edg') > -1) {
      sBrowser = 'Microsoft Edge'
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome or Chromium'
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'Apple Safari'
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = 'unknown'
    }

    console.log('sBrowser>>>>', sBrowser)

    return sBrowser
  }

  const getBrowserVersion = () => {
    const userAgent = navigator.userAgent
    const versionIndex =
      userAgent.indexOf('rv:') ||
      userAgent.indexOf('Edge/') ||
      userAgent.indexOf('Chrome/') ||
      userAgent.indexOf('Firefox/') ||
      userAgent.indexOf('Safari/')

    if (versionIndex > -1) {
      return userAgent.substring(versionIndex + 3, userAgent.indexOf('.', versionIndex))
    }

    return 'Unknown'
  }

  const {userId, token}: any = useSelector((state) => {
    return state
  })

  const dispatch = useDispatch()

  const [loadingLoader, setLoading] = useState(false)
  const {saveAuth} = useAuth()
  const [status, setstatus] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (
      values
      // {setStatus, setSubmitting}
    ) => {
      setstatus(false)
      setLoading(true)
      try {
        const email = values.email
        const password = values.password
        // adminUserLogin({
        //   variables: {
        //     email,
        //     password,
        //     // deviceType: 'web',
        //     browserUniqueId: unique_id,
        //     ipAddress: ip,
        //     userAgent: browserDetails.userAgent,
        //     browserName: browserDetails.name,
        //   },
        // })

        await createData({
          email,
          password,
          // deviceType: 'web',
          browserUniqueId: unique_id,
          ipAddress: ip,
          userAgent: browserDetails.userAgent,
          browserName: browserDetails.name,
        })

        setLoading(false)
      } catch (error) {
        saveAuth(undefined)
        setstatus(true)
        // setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const {createData, loading, error, data} = useMutationApi(LOGIN_MUTATION)

  // const [adminUserLogin, {data, loading, error}] = useMutation(LOGIN_MUTATION)

  const {
    createData: otpVerify,
    loading: loading1,
    error: error1,
    data: data1,
  } = useMutationApi(OTPVERIFY_MUTATION)

  // const [verifyAdminOTP, {data: data1, loading: loading1, error: error1}] =
  //   useMutation(OTPVERIFY_MUTATION)

  useEffect(() => {
    if (data) {
      alert(`Your OTP is ${data.adminUserLogin.otp}`)
      setModalState(true)
      setstatus(false)
    }
    if (error) {
      saveAuth(undefined)
      setstatus(true)
      setLoading(false)
    }
  }, [data, error])

  useEffect(() => {
    if (data1) {
      setModalState(false)
      dispatch(actionsUsers.updateState(data1.verifyAdminOtp))
      dispatch(actionsUsers.updateuuid(unique_id))
    }
    if (error1) {
      saveAuth(undefined)
      // setstatus(true)
      console.log('Error??????', error1)
      setLoading(false)
    }
  }, [data1, error1])

  const verifyOTP = async () => {
    // verifyAdminOTP({
    //   variables: {
    //     email: data?.adminUserLogin.email,
    //     countryCode: data?.adminUserLogin.countryCode,
    //     mobileNumber: data?.adminUserLogin.mobileNumber,
    //     otp: parseInt(otp),
    //     browserName: browserDetails.name,
    //     browserUniqueId: unique_id,
    //     ipAddress: ip,
    //     userAgent: browserDetails.userAgent,
    //   },
    // })

    await otpVerify({
      email: data?.adminUserLogin.email,
      countryCode: data?.adminUserLogin.countryCode,
      mobileNumber: data?.adminUserLogin.mobileNumber,
      otp: parseInt(otp),
      browserName: browserDetails.name,
      browserUniqueId: unique_id,
      ipAddress: ip,
      userAgent: browserDetails.userAgent,
    })
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
      onKeyDown={(e) => {
        if (e.key === 'Enter') console.log('Enter pressed')
      }}
    >
      <Modal
        show={modalState}
        dialogClassName='ePulse-modal ePulse-modal-50w'
        onHide={() => setModalState(false)}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <span className='fs-3 fw-bold'>Enter OTP to Verify</span>
        </Modal.Header>
        <Modal.Body className='container' />
        <div className='ePulse-modal-content'>
          {/* <form
            onSubmit={formik.handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') console.log('Enter pressed')
            }}
          > */}
          <div className='d-flex flex-column justify-content-between mb-4'>
            <div className='col form-block'>
              <span className='form-label required'>OTP</span>
              <input
                type={'number'}
                className='form-control px-3 '
                value={otp}
                onChange={(e: any) => setOtp(e.target.value)}
              />
            </div>
            {error1 && error1 && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger'>{error1?.message}</span>
              </div>
            )}
          </div>
          {/* <div className='d-flex flex-row justify-content-end mt-4'>
              <button
                className='border border-dark my-4 bg-light py-2 fs-6 rounded-pill text-muted col-2'
                onClick={() => setModalState(false)}
              >
                Cancel
              </button>
              <button
                className={
                  'border-0 my-4 ePulse-appColor-primary py-2 fs-6  ms-6 rounded-pill text-white col-2'
                }
                onClick={() => verifyOTP()}
                // disabled={otp === '' || otp.length !== 6}
                type='button'
              >
                Verify OTP
              </button>
            </div> */}

          <div className='btn-row'>
            <button
              className={otp === '' ? 'ePulse-button-secondory' : 'ePulse-btn-primary'}
              type='button'
              onClick={() => verifyOTP()}
              disabled={otp === ''}
            >
              {!loading1 && 'Verify OTP'}
              {loading1 && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </div>
      </Modal>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          Sign in to myePulse
          {/* {' '}
          <a className='' style={{color: '#000FD0'}}>
            myePulse
          </a> */}
        </h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className=' fw-bolder' style={{color: '#000FD0'}}>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error?.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='form-group'>
        <label className='form-label'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span className='form-error' role='alert'>
              {formik.errors.email}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='form-group'>
        {/* <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'> */}
        {/* begin::Label */}
        <label className='form-label'>Password</label>
        {/* end::Label */}
        {/* begin::Link */}
        {/* <Link
              to='/auth/forgot-password'
              className=' fs-6 fw-bolder'
              style={{marginLeft: '5px', color: '#000FD0'}}
            >
              Forgot Password ?
            </Link> */}
        {/* end::Link */}
        {/* </div>
        </div> */}
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='form-error' role='alert'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='d-flex justify-content-between my-10'>
        <div>
          <input id='remember' type='checkbox' value='Remember' /> <label>Remember me</label>
        </div>
        <div>
          <Link className='fw-bold' to='/auth/forgot-password'>
            Forgot Password ?
          </Link>
        </div>
      </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg  w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loadingLoader && <span className='indicator-label'>Sign In</span>}
          {loadingLoader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
