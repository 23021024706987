import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_ePulse/helpers'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_ePulse/layout/core'
import '../../../App.css'
import {addDays} from 'date-fns'
import {Statics} from './statics'
import {Users} from './Users'
import {Clinics} from './Clinics'
import {RecentActivities} from './RecentActivities'
import {ApprovalRequest} from './ApprovalRequest'
import {useQuery} from '@apollo/client'
import {DASHBOARD_COUNT} from '../../GraphQLQueries/Dashboard'
import {checkPermission} from '../../App'
import {useDispatch, useSelector} from 'react-redux'
import {actionsUsers} from '../../../setup/redux/rootReducer'
import {toast} from 'react-toastify'
import {ToastMessageContainer} from '../../../_ePulse/layout/components/ToastMessageContainer'
import {updateDateFormat} from '../../../data/config'
import useApi from '../../../hooks/useQuery'
import moment from 'moment'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const [roleType, setRoleType] = useState('Doctor')
  const updateDates = (dates: any) => {
    setState(dates)
    dispatch(actionsUsers.updateStartDate(updateDateFormat(dates[0].startDate)))
    dispatch(actionsUsers.updateEndDate(updateDateFormat(dates[0].endDate)))
  }
  const [dashboardCount, setDashboardCount] = useState<any>({})

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 30),
      key: 'selection',
    },
  ])

  const reduxData: any = useSelector((state) => {
    return state
  })

  // const {
  //   data: resData,
  //   loading: loading1,
  //   error: error1,
  //   refetch: refetch1,
  // } = useApi(true, DASHBOARD_COUNT, {
  //   endDate: moment(reduxData.endDate).endOf('day'),
  //   startDate: moment(reduxData.startDate).startOf('day'),
  // })

  // useEffect(() => {
  //   if (resData) {
  //     const res = resData?.getDashboardCount
  //     setDashboardCount(res)
  //   }
  // }, [resData])

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  console.log('roleTyperoleType', roleType)

  return (
    <>
      <ToastMessageContainer />
      <Statics dateState={(dates: any) => updateDates(dates)} />
      <div className='d-flex flex-row mt-4 dashboard-landing'>
        <div className='d-flex flex-column w-75 dashboard-left'>
          <div className='d-flex flex-row w-100 dashboard-counters'>
            <div
              className='col count-block'
              onClick={() => setRoleType('Doctor')}
              style={{cursor: 'pointer'}}
            >
              <div className='count-block-content'>
                <div className='content-name'>Doctors</div>
                <div className='content-count'>{dashboardCount?.clinicCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/doctor.svg')}
                className='h-45px'
                alt=''
              />
            </div>
            <div
              className='col count-block'
              onClick={() => setRoleType('Consumer')}
              style={{cursor: 'pointer'}}
            >
              <div className='count-block-content'>
                <div className='content-name'>Consumers</div>
                <div className='content-count'>{dashboardCount?.patientCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/consumers.svg')}
                className='h-45px'
                alt=''
              />
            </div>
            <div
              className='col count-block'
              onClick={() => setRoleType('Pharmacy')}
              style={{cursor: 'pointer'}}
            >
              <div className='count-block-content'>
                <div className='content-name'>Pharmacy</div>
                <div className='content-count'>{dashboardCount?.pharmacyCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/pharmacies.svg')}
                className='h-45px'
                alt=''
              />
            </div>
            <div
              className='col count-block'
              onClick={() => setRoleType('ScanCenter')}
              style={{cursor: 'pointer'}}
            >
              <div className='count-block-content'>
                <div className='content-name'>Scan and Diagnostic</div>
                <div className='content-count'>{dashboardCount?.labCount}</div>
              </div>
              <img
                src={toAbsoluteUrl('/media/ePulse/icons/dashboard/diagnostic.svg')}
                className='h-45px'
                alt=''
              />
            </div>
          </div>

          {roleType === 'Consumer' ? (
            <>{checkPermission('getDashboardUserList') && <Users />}</>
          ) : (
            <>
              {
                // checkPermission('getBusinessList') &&
                checkPermission('getDashboardBusinessList') && (
                  <Clinics
                    roleType={roleType}
                    dashboardCount={(count: any) => setDashboardCount(count)}
                  />
                )
              }
            </>
          )}
        </div>
        <div className='d-flex flex-column col ms-5 dashboard-right'>
          {/* {checkPermission('getApprovalPendingBusinessList') && */}
          <ApprovalRequest />
          {/* } */}

          {/* <RecentActivities /> */}
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
