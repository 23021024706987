import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../_ePulse/helpers'
import {Toolbar} from '../../../_ePulse/layout/components/toolbar/Toolbar'

import SVG from 'react-inlinesvg'
import {useNavigate} from 'react-router-dom'
import '../../../App.css'
import {MenuComponent} from '../../../_ePulse/assets/ts/components'
export const ClinicListTable = (props: any) => {
  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization() // import from _metronic/assets/ts/components
  }, [])

  return (
    <>
      <div className='dashboard-table-row '>
        <div className='d-flex  align-items-center col-4'>
          <div className='d-flex flex-column me-2 '>
            <div className='d-flex flex-stack '>
              <span className='col-value'>{props.clinic.businessName}</span>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column me-2 col-3 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>
              {props.clinic.firstName} {props.clinic.lastName}
            </span>
          </div>
        </div>
        <div className='d-flex flex-column me-2 col-3  justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>{props.clinic.businessType || 'NA'}</span>
          </div>
        </div>

        {/* <div className='d-flex flex-column me-2 col-3 align-items-end justify-content-center '>
          <div className='d-flex w-120px justify-content-center'>
            <a
              // href='javascript:void(0);'
              className='btn btn-light btn-active-light-primary btn-sm'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG path='/media/ePulse/action_button.svg' className='svg-icon-2 m-0' />
            </a>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              <div className=' fld-ex align-items-center justify-content-center py-1'>
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <KTSVG path='/media/ePulse/view.svg' className='svg-icon-4 m-0' />
                  <button
                    onClick={() =>
                      navigate('/business/view', {state: {clinicId: props?.clinic?.businessId}})
                    }
                    className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
                  >
                    View
                  </button>
                </div>
              </div>

              <div className=' d-flex align-items-center justify-content-center py-1'>
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <KTSVG path='/media/ePulse/edit.svg' className='svg-icon-4 m-0' />
                  <button
                    onClick={() =>
                      navigate('/business/edit', {state: {clinicId: props?.clinic?.businessId}})
                    }
                    className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className='d-flex flex-row me-2 col-2 justify-content-center '>
          <div className='d-flex flex-row align-items-center justify-content-center'>
            <button
              onClick={() =>
                navigate('/business/view', {state: {clinicId: props?.clinic?.businessId}})
              }
              className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
            >
              <KTSVG path='/media/ePulse/view_icon.svg' className='m-0' />
            </button>
          </div>
          <div className='d-flex flex-row align-items-center justify-content-center'>
            <button
              onClick={() =>
                navigate('/business/edit', {state: {clinicId: props?.clinic?.businessId}})
              }
              className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
            >
              <KTSVG path='/media/ePulse/edit_icon.svg' className='m-0' />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
