import {gql} from '@apollo/client'

export const GET_CLINIC_PROFILE = gql`
  query GetClinincProfile($id: ID!) {
    getClinicProfile(clinicId: $id) {
      clinicId
      clinicState
      isProfileCompleted
      clinicManagerFirstName
      clinicManagerLastName
      clinicName
      clinicPhone
      clinicEmail
      clinicCity
      clinicState
      clinicLogo
      isApproved
      clinicAddress {
        street
        city
        state
        zipCode
        country
      }
      clinicTechnicalDetails {
        mciNumber
        stateCouncil
        speciality
      }
    }
  }
`
export const GET_BUSINESS_PROFILE = gql`
  query GetClinincProfile($id: ID!) {
    getBusinessDetails(businessProfileId: $id) {
      id
      userId

      isActive
      businessType
      isApproved
      businessStep {
        businessStepKeyValue {
          stepName
          isCompleted
          isVerified
          __typename
        }
        __typename
      }
      userProfile {
        firstName
        lastName
        email
        userId
        aadhaarCard
        bloodGroup
        advanceDegrees
        userTechDetails {
          mciNumber
          stateCouncil
          specialty
          isDoctorCertified
          doctorCertificates
          __typename
        }
        isProfileCompleted
        __typename
      }
      businessProfile {
        businessName
        businessEmail
        businessType
        countryCode
        businessPhone
        businessTechnicalDetails {
          mciNumber
          businessRegistrationNumber
          __typename
        }
        __typename
      }
      businessAddress {
        id
        zipCode
        street
        city
        district
        state
        country
        __typename
      }
      userAddress {
        id
        zipCode
        street
        city
        district
        state
        country
        __typename
      }

      __typename
    }
  }
`

export const EDIT_BUSINESS_PROFILE = gql`
  mutation editDoctorProfile(
    $userRoleId: ID!
    $isActive: Boolean!
    $firstName: String!
    $lastName: String!
    $email: String
    $mciNumber: String!
    $stateCouncil: String!
    $specialty: String!
    $businessName: String!
    $businessEmail: String!
    $countryCode: Int!
    $businessPhone: MobileNumber!
    $businessAddress: String!
    $businessZip: String!
    $businessCity: String!
    $businessDistrict: String
    $businessState: String!
    $businessCountry: String!
    $isDoctorCertified: Boolean
    $bloodGroup: String
    $aadhaarCard: String
    $advanceDegrees: String
  ) {
    editDoctorProfile(
      userRoleId: $userRoleId
      isActive: $isActive
      profileDataInput: {
        userProfileInput: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          aadhaarCard: $aadhaarCard
          bloodGroup: $bloodGroup
          advanceDegrees: $advanceDegrees
          userTechDetails: {
            mciNumber: $mciNumber
            stateCouncil: $stateCouncil
            specialty: $specialty
            isDoctorCertified: $isDoctorCertified
          }
        }
        businessProfileInput: {
          businessName: $businessName
          businessEmail: $businessEmail
          countryCode: $countryCode
          businessPhone: $businessPhone
          businessAddress: $businessAddress
          businessZip: $businessZip
          businessCity: $businessCity
          businessDistrict: $businessDistrict
          businessState: $businessState
          businessCountry: $businessCountry
        }
      }
    ) {
      id
    }
  }
`

export const EDIT_BUSINESS_FEES = gql`
  mutation editEpulseFeesForBusiness(
    $businessProfileId: ID!
    $epulseFeesId: ID!
    $fees: Int!
    $feesType: feesType!
  ) {
    editEpulseFeesForBusiness(
      businessProfileId: $businessProfileId
      epulseFeesId: $epulseFeesId
      epulseFeesInput: {fees: $fees, feesType: $feesType}
    ) {
      id
    }
  }
`

export const EDIT_CLINIC_PROFILE = gql`
  mutation EditMasterClinicProfile(
    $clinicId: ID!
    $clinicManagerFirstName: String!
    $clinicManagerLastName: String!
    $clinicName: String!
    $clinicCity: String!
    $clinicState: String!
    $mciNumber: String!
    $stateCouncil: String!
    $speciality: String!
  ) {
    editMasterClinicProfile(
      clinicId: $clinicId
      clinicInput: {
        clinicManagerFirstName: $clinicManagerFirstName
        clinicManagerLastName: $clinicManagerLastName
        clinicName: $clinicName
        clinicCity: $clinicCity
        clinicState: $clinicState
        clinicTechnicalDetails: {
          mciNumber: $mciNumber
          stateCouncil: $stateCouncil
          speciality: $speciality
        }
      }
    ) {
      clinicId
      clinicManagerFirstName
      clinicManagerLastName
    }
  }
`

export const UPDATE_CLINIC_STATUS = gql`
  mutation ActiveInactiveClinic($status: String!, $clinicId: ID!) {
    activeInactiveClinic(status: $status, clinicId: $clinicId)
  }
`
export const GET_CLINIC_PLAN = gql`
  query getMasterSubscription($clinicId: ID!) {
    getMasterSubscription(subscriptionRoleId: $clinicId) {
      id
      subscriptionRoleId
      billingPlanId
      pricingOptionId
      billingPlan {
        id
        name
        description
        features {
          id
          name
          description
        }
        pricingOptions {
          id
          price
          currency
          planIntervalUnit
          planIntervalValue
          totalAmount
        }
        trialPeriodValue
        trialPeriodUnit
      }
    }
  }
`

export const APPROVE_CLINIC = gql`
  mutation ApproveClinic(
    $clinicId: String!
    $clinicName: String!
    $discountCoupon: String
    $billingPlanId: ID!
    $pricingOptionId: ID!
  ) {
    approveClinic(
      clinicId: $clinicId
      clinicName: $clinicName
      billingPlan: {
        billingPlanId: $billingPlanId
        pricingOptionId: $pricingOptionId
        discountCoupon: $discountCoupon
      }
    ) {
      isRegisteredUser
    }
  }
`

export const APPROVE_BUSINESS = gql`
  mutation approveBusiness($userRoleId: ID!) {
    approveBusiness(userRoleId: $userRoleId)
  }
`

export const GET_CLINIC_SUBSCRIPTION_HISTORY = gql`
  query getMasterSubscriptionHistory($clinicId: ID!) {
    getMasterSubscriptionHistory(subscriptionRoleId: $clinicId, listFilter: {page: 1, limit: 100}) {
      data {
        subscriptionRoleId
        billingPlanId
        pricingOptionId
        planName
        planDescription
        billingPricing {
          id
          price
          currency
          planIntervalUnit
          planIntervalValue
          totalAmount
        }
        status
        isCancelled
        cancelledDate
      }
    }
  }
`

export const GET_CLINIC_LIST = gql`
  query getBusinessList(
    $limit: Int!
    $page: Int!
    $businessType: String
    $name: String
    $countryCode: Int
    $mobileNumber: MobileNumber
  ) {
    getBusinessList(
      businessType: $businessType
      name: $name
      countryCode: $countryCode
      mobileNumber: $mobileNumber
      listFilter: {page: $page, limit: $limit}
    ) {
      data {
        id
        businessId
        businessType
        firstName
        lastName
        profilePhoto
        businessName
        isActive
        businessPhone
        businessRegisterDate
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const CLININC_APPROVAL_REQUEST = gql`
  query getApprovalPendingBusinessList($startDate: DateTime!, $endDate: DateTime!) {
    getApprovalPendingBusinessList(
      listFilter: {startDate: $startDate, endDate: $endDate, page: 1, limit: 100}
    ) {
      data {
        id
        businessType
        firstName
        lastName
        profilePhoto
        businessName
        businessRegisterDate
        businessId
        createdDate
      }
      total
      pageSize
      totalPages
    }
  }
`

export const CLINIC_QUERY = gql`
  query GetDashboardClinicList($startDate: Date!, $endDate: Date!) {
    getDashboardClinicList(
      listFilter: {startDate: $startDate, endDate: $endDate, page: 1, limit: 10}
    ) {
      data {
        id
        clinicId
        clinicManagerFirstName
        clinicManagerLastName
        clinicName
        clinicCity
      }
      pageSize
      total
      page
      totalPages
    }
  }
`
export const GET_APPROVED_BUSINESS_LIST = gql`
  query getDashboardBusinessList(
    $startDate: DateTime!
    $endDate: DateTime!
    $businessType: String!
  ) {
    getDashboardBusinessList(
      businessType: $businessType
      listFilter: {startDate: $startDate, endDate: $endDate, page: 1, limit: 10}
    ) {
      data {
        id
        businessId
        firstName
        lastName
        businessType
        businessName
      }
      pageSize
      total
      page
      totalPages
    }
  }
`

export const UPDATE_DOCUMENT = gql`
  mutation updateDoctorDocument(
    $documentId: ID
    $userId: ID!
    $documentTitle: String!
    $documentName: String!
    $documentUrl: String!
  ) {
    updateDoctorDocument(
      documentId: $documentId
      userId: $userId
      document: {
        documentName: $documentName
        documentTitle: $documentTitle
        documentUrl: $documentUrl
      }
    )
  }
`

export const DELETE_DOCUMENT = gql`
  mutation deleteDoctorDocument($userId: ID!, $documentName: String!, $docUrl: String!) {
    deleteDoctorDocument(documentName: $documentName, userId: $userId, docUrl: $docUrl)
  }
`

export const UPLOAD_FILE = gql`
  mutation uploadFileInOnlyS3($documentDetail: FileInput!) {
    uploadFileInOnlyS3(documentDetail: $documentDetail)
  }
`

export const GET_BUSINESS_FEES = gql`
  query getEpulseFeesForBusiness($businessProfileId: ID!) {
    getEpulseFeesForBusiness(businessProfileId: $businessProfileId) {
      id
      moduleName
      serviceLabel
      fees
      feesType
      taxes
      isActive
      __typename
    }
  }
`

export const BUSINESS_FEE_HISTORY = gql`
  query getEpulseFeesHistoryForBusiness($limit: Int!, $page: Int!, $businessId: ID!) {
    getEpulseFeesHistoryForBusiness(
      BusinessId: $businessId
      listFilter: {page: $page, limit: $limit}
    ) {
      data {
        id
        masterFeesId
        moduleName
        serviceLabel
        fees
        feesType
        validFrom
        validTill
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`
