import moment from 'moment'

export const policy_types = [
  {
    id: 'Privacy',
    label: 'Privacy',
  },
  {
    id: 'TermsCondition',
    label: 'Terms & Condition',
  },
  {
    id: 'ApplicationPolicy',
    label: 'Application Policy',
  },
  {
    id: 'RefundPolicy',
    label: 'Refund Policy',
  },
  {
    id: 'EULA (End user license agreement)',
    label: 'EULA (End user license agreement)',
  },
]

export const application_setting_tabs = [
  {
    title: 'Auth',
    path: '/application_setting/auth',
  },
  {
    title: 'Web Settings',
    path: '/application_setting/web_setting',
  },
  {
    title: 'SMTP Settings',
    path: '/application_setting/smtp_setting',
  },
  {
    title: 'SMS Settings',
    path: '/application_setting/sms_setting',
  },
  {
    title: 'General Settings',
    path: '/application_setting/general_setting',
  },
  {
    title: 'Payment Credentials',
    path: '/application_setting/payment_setting',
  },
  {
    title: 'Appointment Settings',
    path: '/application_setting/appointment_setting',
  },
  {
    title: 'Social Settings',
    path: '/application_setting/social_setting',
  },
  {
    title: 'Fresdesk Settings',
    path: '/application_setting/freshdesk_setting',
  },
  {
    title: 'App Version Settings',
    path: '/application_setting/appversion_setting',
  },
]

export const updateDateFormat = (date: any) => {
  const currentTime = moment() // Get the current time
  // Parse the input date and format it without time
  const parsedDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss ZZ')
  const formattedDate = parsedDate.format('YYYY-MM-DD') // Format the date
  // Combine the formatted date with the current time
  const combinedDateTime = moment(`${formattedDate} ${currentTime.format('HH:mm:ss')}`).utc()
  return combinedDateTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}

export const camelCase = (str: any) => {
  // // converting all characters to lowercase
  // let ans = str.toLowerCase()

  // // Returning string to camelcase
  // return ans.split(' ').reduce((s: any, c: any) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
  // return str.replace(/[-_]+(.)/g, (_: any, char: any) => char.toUpperCase())
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function convertToCamelCase(inputString: any) {
  // Remove symbols and split the string into words
  let words = inputString
    ?.toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, ' ')
    .split(' ')

  // Capitalize the first letter of each word
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  // Join the words with spaces
  return words?.join(' ')
}
export const checkMobileNoValid = (number: any) => {
  // if (/^\d*$/.test(number) && number.length <= 10) {
  //   return true
  // } else {
  //   return false
  // }
  if (number === '' || (/^\d*$/.test(number) && number.length <= 10)) {
    return true
  } else {
    return false
  }
}

export const checkAadharCardValid = (number: any) => {
  if (number === '' || (/^\d*$/.test(number) && number.length <= 12)) {
    return true
  } else {
    return false
  }
}

export const approval_steps = [
  {
    key: 'mobile_number',
    value: 'Mobile Number',
  },
  {
    key: 'mcinumber',
    value: 'MCINumber',
  },
  {
    key: 'email',
    value: 'Email',
  },
  {
    key: 'business_address',
    value: 'Business Address',
  },
]

export const pharmacy_steps = [
  {
    key: 'service_n_subscriptions',
    value: 'Service & Subscriptions',
    selected: false,
  },
  {
    key: 'business_operating_hours',
    value: 'Business Operating Hours',
    selected: true,
  },
  {
    key: 'holidays',
    value: 'Holidays',
    selected: false,
  },
  {
    key: 'currency_settings',
    value: 'Currency Settings',
    selected: false,
  },
  {
    key: 'add_dr_staff',
    value: 'Add Staff',
    selected: false,
  },
]

export const doctor_step = [
  {
    key: 'service_n_subscriptions',
    value: 'Service & Subscriptions',
    selected: false,
  },
  {
    key: 'business_operating_hours',
    value: 'Business Operating Hours',
    selected: true,
  },
  {
    key: 'holidays',
    value: 'Holidays',
    selected: false,
  },
  {
    key: 'consultation_fees',
    value: 'Consultation Fees',
    selected: false,
  },
  {
    key: 'add_dr_staff',
    value: 'Add Dr./Staff',
    selected: false,
  },
  {
    key: 'visiting_hours',
    value: 'Visiting Hours',
    selected: false,
  },
  {
    key: 'add_patient',
    value: 'Add Patient',
    selected: false,
  },
]

export const scan_center_step = [
  {
    key: 'service_n_subscriptions',
    value: 'Service & Subscriptions',
    selected: false,
  },
  {
    key: 'business_operating_hours',
    value: 'Business Operating Hours',
    selected: true,
  },
  {
    key: 'holidays',
    value: 'Holidays',
    selected: false,
  },
  {
    key: 'currency_settings',
    value: 'Currency Settings',
    selected: false,
  },
  {
    key: 'add_dr_staff',
    value: 'Add Staff',
    selected: false,
  },

  {
    key: 'consultation_fees',
    value: 'Lab Charges',
    selected: false,
  },
  {
    key: 'product_Offerings',
    value: 'Product Offerings',
    selected: false,
  },
  {
    key: 'labs',
    value: 'Lab',
    selected: false,
  },
]

export const doctor_certificates = [
  {
    title: 'MCI Certificate',
    certificateName: 'mciCertificates',
  },
  {
    title: 'Aadhar Card',
    certificateName: 'aadharCard',
  },
  {
    title: 'Graduation Degree',
    certificateName: 'graduationDegree',
  },
  {
    title: 'PAN Card',
    certificateName: 'panCard',
  },
  {
    title: 'Intemediate Certificate',
    certificateName: 'intemediateDegree',
  },
]
